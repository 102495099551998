import React from 'react';
import {
    ticketOutline, barChartOutline, newspaperOutline, serverOutline, readerOutline, receiptOutline, bookOutline, personCircleOutline, callOutline, earthOutline, lockOpenOutline
} from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

class Icono extends React.Component {

    renderSwitch(param) {
        switch (param) {
            case '1':
                return <IonIcon className="iconos" icon={personCircleOutline} />;
            case '2':
                return <IonIcon className="iconos" icon={barChartOutline} />;
            case '3':
                return <IonIcon className="iconos" icon={readerOutline} />;
            case '4':
                return <IonIcon className="iconos" icon={newspaperOutline} />;
            case '5':
                return <IonIcon className="iconos" icon={serverOutline} />;
            case '6':
                return <IonIcon className="iconos" icon={receiptOutline} />;
            case '7':
                return <IonIcon className="iconos" icon={bookOutline} />;
            case '8':
                return <IonIcon className="iconos" icon={ticketOutline} />;
            case '9':
                return <IonIcon className="iconos" icon={callOutline} />;
            case '10':
                return <IonIcon className="iconos" icon={earthOutline} />;
            case '11':
                return <IonIcon className="iconos" icon={lockOpenOutline} />;
        }
    }


    render() {
        return (
            <>{this.renderSwitch(this.props.datos)}</>
        )
    }
}
export default Icono;