import React from 'react';
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import Icono from '../Elementos/Icono';
import { powerOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import PageLoading from '../PantallaCarga/PageLoading';

var botonesData = [];


const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: '0 auto',
    },
};

class MenuHomeBtnTranslated extends React.Component {
    state = {
        misDatos: true,
        consumo: true,
        contratos: true,
        recargas: true,
        facturas: true,
        tarifas: true,
        documentos: true,
        noticias: true,
        incidencias: true,
        roaming: true,
        pin: true,
        desvios: true,
        loading: true,
        desactivado: false,
        orden: []
    };

    componentDidMount() {
        const funciones1 = JSON.parse(localStorage.getItem('funciones'));
        const funciones = funciones1[0];

        const appConf = localStorage.getItem("appconf");

        if (appConf != null && appConf != undefined && appConf != '' && appConf != 'undefined') {
            const appConf1 = JSON.parse(appConf);
            botonesData = appConf1.orden_menu;
        }


        if (appConf.activacion_servicios == 0) {
            this.setState({ desvios: false });
        }

        if (appConf.mostrar_puk == 0) {
            this.setState({ pin: false });
        }

        if (funciones.roaming == 0) {
            this.setState({ roaming: false });
        }

        if (funciones.misDatos == 0) {
            this.setState({ misDatos: false });
        }

        if (funciones.consumo == 0) {
            this.setState({ consumo: false });
        }

        if (funciones.contratos == 0) {
            this.setState({ contratos: false });
        }

        if (funciones.recargas == 0) {
            this.setState({ recargas: false });
        }
        if (funciones.facturas == 0) {
            this.setState({ facturas: false });
        }

        if (funciones.tarifas == 0) {
            this.setState({ tarifas: false });
        }

        if (funciones.documentos == 0) {
            this.setState({ documentos: false });
        }

        if (funciones.noticias == 0) {
            this.setState({ noticias: false });
        }

        if (funciones.incidencias == 0) {
            this.setState({ incidencias: false });
        }

        if (localStorage.getItem('permisos') !== undefined && localStorage.getItem('permisos') !== '' && localStorage.getItem('permisos') != null) {
            const permisosApp = JSON.parse(localStorage.getItem('permisos'));
            if (!permisosApp.includes(1)) {
                this.setState({ noticias: false });
            }
            if (!permisosApp.includes(2)) {
                this.setState({ facturas: false });
            }
            if (!permisosApp.includes(3)) {
                this.setState({ consumo: false });
            }
            if (!permisosApp.includes(4)) {
                this.setState({ incidencias: false });
            }
            if (!permisosApp.includes(5)) {
                this.setState({ contratos: false });
            }
            if (!permisosApp.includes(6)) {
                this.setState({ documentos: false });
            }
        }

        this.setState({ loading: false });
    }


    render() {
        if (this.state.loading === true) {
            return <PageLoading />;
        }
        const { t } = this.props;
        return (
            <React.Fragment>
                <div style={styles.container}>
                    {botonesData
                        .sort((a, b) => a.posicion - b.posicion)
                        .map(boton => (
                            this.state[boton.titulo] && <div className="div-home" style={styles.button}>
                                <Link to={boton.link} className="link-entradas">
                                    <div className="iconos-home-div">
                                        <Icono datos={boton.id} />
                                    </div>
                                    <p className="entradas-menu">{t(boton.traduccion,)}</p>
                                </Link>
                            </div>

                        ))}
                </div>
                <div className="div-home-cerrar">
                    <Link to="/Login" className="link-entradas">
                        <div className="iconos-home-div">
                            <IonIcon className="iconos icon-red-home" icon={powerOutline} />
                        </div>
                        <p className="entradas-menu">{t('Home.cerrar',)} </p>
                    </Link>
                </div>
            </React.Fragment>
        )
    }
};

const MenuHomeBtn = withTranslation('common')(MenuHomeBtnTranslated);
export default MenuHomeBtn;